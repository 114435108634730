jQuery(function() { 
    if ($('#degree-filter-content').length) {

        //Get the API degree URL based on this installation's setup
        let degree_url = ''; //Default

        //This installation's url for querying
        let degree_fetch_url = document.location.protocol + '//' + document.location.hostname + '/wp-json/degrees/url';

        //The main container of the mixer content
        const container = document.querySelector('[data-ref="container"]');

        //Updated search
        let inputSearch = document.querySelector('[data-ref="input-search"]');
        let degreeSearchTimeout; //Timeout for the input search
        const page_limit = 10;
        let currentPage = 1;
        //This will not change, and it will be used in a few places like the search input
        const defaultControlUrl = degree_url + '/wp-json/wp/v2/degrees?_fields=id,title,excerpt,meta,link&orderby=title&per_page=' + page_limit + '&order=asc';
        //This is the default url for category meta querying.. This too, will not change
        const defaultCategoryUrl = degree_url + '/wp-json/degrees/category_filter?orderby=title&per_page=' + page_limit;
        //Setup search url parameter
        let searchParam = '';
        //The categories for the query string when someone selects from the dropdown
        let categories = '';
        //This will be used throughout the MixItUp section, etc.
        //Essentially, this is the current context URL (what controls the rendered data)
        //So, if I'm clicking through the pagination or filtering, this will change to meet that query
        let controlUrl = defaultControlUrl + '&page=' + currentPage + searchParam;

        //The mixer (needs to be located AFTER the $.each from above)
        const mixer = mixitup(container, {
            debug: {
                enable: true,
                showWarnings: true
            },
            data: {
                uidKey: 'id', //This is required to be in the JSON list
            },
            load: {
                sort: 'title:asc'
            },
            render: {
                target: render
            },
            animation: {
                enable: false
            },
            selectors: {
                target: '[data-ref="degree-item"]',
            }
        });

        $.ajax({
            url: degree_fetch_url,
            async: false,
            dataType: 'json',
            success: function(data) {
                if (data != '') {
                    degree_url = data; //We have the installation's degree URL
                } else {
                    degree_url = document.location.protocol + '//' + document.location.hostname;
                }
            }
        });

        //The HTML for the individual degree card
        function render(item) {
            return '<div class="mix program-block card-' + item.id + '" data-ref="degree-item" data-title="' + item.post_title + '" data-id="' + item.id + '">' +
                '<div class="btn btn-programs collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#program_' + item.id + '" aria-expanded="false" aria-controls="program_' + item.id + '">' +
                    '<span class="program-title">' + item.title.rendered + '</span>' +
                    '<span class="sub-title">' + item.meta.college_school + '</span>' +
                '</div>' +
                '<div class="collapse" id="program_' + item.id + '">' +
                '<div class="card card-body">' + item.excerpt.rendered +
                    '<a href="' + item.link  + '" class="btn-link btn-rt-icon text-blue mt-4">Learn More About ' + item.title.rendered + '</a>' +
                    '<hr class="uncg-hr-blue">' +
                    '<div class="row">' +
                        '<div class="col-6">' +
                        '<span class="font-sofia-bold">Degree Type:</span><br>' + item.meta.degree_type +
                        '</div>' +
                        '<div class="col-6">' +
                        '<span class="font-sofia-bold">Program Type:</span><br>' +
                        item.meta.program_type +
                        '</div>' +
                    '</div>' +
                '</div>' + '</div>' + '</div>' + '</div>';
        }


        //The HTML for the individual categories lists
        function renderSelectCat(item)
        {
            return '<option value="' + encodeURIComponent(item.name) + '">' + item.name + '</option>';
        }

        function loadCategoryDropdowns()
        {
            let degreesTypeUrl = degree_url + "/wp-json/wp/v2/degree_type?_fields=id,link,name,slug&per_page=100";
            $.ajax({
                url: degreesTypeUrl,
                async: false,
                dataType: 'json',
                success: function(data) {
                    $.each(data, function(id, item) {
                        $('#degree-type-dropdown').append(
                            $( renderSelectCat( item ) )
                        );
                    });
                }
            });

            let collegeSchoolUrl = degree_url + "/wp-json/wp/v2/college_school?_fields=id,link,name,slug&per_page=100";
            $.ajax({
                url: collegeSchoolUrl,
                async: false,
                dataType: 'json',
                success: function(data) {
                    $.each(data, function(id, item) {
                        $('#college-school-dropdown').append(
                            $( renderSelectCat( item ) )
                        );
                    });
                }
            });

            let programTypeUrl = degree_url + "/wp-json/wp/v2/program_type?_fields=id,link,name,slug&per_page=100";
            $.ajax({
                url: programTypeUrl,
                async: false,
                dataType: 'json',
                success: function(data) {
                    $.each(data, function(id, item) {
                        $('#program-type-dropdown').append(
                            $( renderSelectCat( item ) )
                        );
                    });
                }
            });

            let preProProgramUrl = degree_url + "/wp-json/wp/v2/pre_pro_program?_fields=id,link,name,slug&per_page=100";
            $.ajax({
                url: preProProgramUrl,
                async: false,
                dataType: 'json',
                success: function(data) {
                    $.each(data, function(id, item) {
                        $('#professional-programs-dropdown').append(
                            $( renderSelectCat( item ) )
                        );
                    });
                }
            });
        }

        function degrees_mixer_data(url)
        {
            $('#degree-filter-content .loading-overlay').removeClass('hide');
            $.ajax({
                url: url,
                success: function (items, status, request) {
                    //Update the mixer with the new dataset
                    mixer.dataset(items).then(function(state) {
                            // nothing here
                        });
                    //These are the total pages
                    let totalPages = request.getResponseHeader('x-wp-totalpages');

                    //Update the pagination
                    degree_pagination(totalPages);

                    //Lastly, get rid of the loader
                    $('#degree-filter-content .loading-overlay').addClass('hide');
                }
            });
        }

        function degree_pagination(totalPages)
        {

            let slot1, slot2, slot3, slot4, slot5;
            slot5 = totalPages;
            if (totalPages < 5) {
                slot5 = 0;
            }
            if (totalPages <= 4) {
                if (currentPage < 4) {
                    slot1 = (totalPages == 0) ? 0 : 1;
                    slot2 = (totalPages <= 1) ? 0 : 2;
                    slot3 = (totalPages <= 2) ? 0 : 3;
                    slot4 = (totalPages <= 3) ? 0 : 4;
                }
            } else {
                if (currentPage < 4) {
                    slot1 = 1; slot2 = 2; slot3 = 3; slot4 = 4;
                } else if (currentPage >= (totalPages - 4)) {
                    slot1 = totalPages - 4;
                    slot2 = totalPages - 3;
                    slot3 = totalPages - 2;
                    slot4 = totalPages - 1;
                } else {
                    slot1 = currentPage - 2;
                    slot2 = currentPage - 1;
                    slot3 = currentPage;
                    slot4 = currentPage + 1;
                }
            }

            let pagination_html = '<li class="page-item degree-page-item"><a class="page-link degree-page-first" data-page="1" role="button">First</a></li>' +
                '<li class="page-item degree-page-item"><a data-page="prev" class="page-link degree-mixitup-control mixitup-control-prev" role="button">Prev</a></li>';

            if (slot1 != 0) {
                pagination_html += '<li class="page-item degree-page-item"><a data-page="' + slot1 + '" class="page-link page-number degree-page-number ' + (slot1 == currentPage ? 'mixitup-control-active' : '') + '" role="button">' + slot1 + '</a></li>';
            }

            if (slot2 != 0) {
                pagination_html += '<li class="page-item degree-page-item"><a data-page="' + slot2 + '" class="page-link page-number degree-page-number ' + (slot2 == currentPage ? 'mixitup-control-active' : '') + '" role="button">' + slot2 + '</a></li>';
            }

            if (slot3 != 0) {
                pagination_html += '<li class="page-item degree-page-item"><a data-page="' + slot3 + '" class="page-link page-number degree-page-number ' + (slot3 == currentPage ? 'mixitup-control-active' : '') + '" role="button">' + slot3 + '</a></li>';
            }

            if (slot4 != 0) {
                pagination_html += '<li class="page-item degree-page-item"><a data-page="' + slot4 + '" class="page-link page-number degree-page-number ' + (slot4 == currentPage ? 'mixitup-control-active' : '') + '" role="button">' + slot4 + '</a></li> <span class="mixitup-control mixitup-control-truncation-marker">��</span>';
            }

            if (slot5 != 0) {
                pagination_html += '<li class="page-item degree-page-item"><a data-page="' + slot5 + '" class="page-link page-number degree-page-number ' + (slot5 == currentPage ? 'mixitup-control-active' : '') + '" role="button">' + slot5 + '</a></li>';
            }

            pagination_html += '<li class="page-item degree-page-item"><a data-page="next" class="page-link   degree-mixitup-control mixitup-control-next" role="button">Next</a></li>' +
                '<li class="page-item degree-page-item"><a class="page-link degree-page-last" role="button">Last</a></li>';

            $('#degrees-programs-pagination').html(pagination_html);

            //First pagination link
            $('.degree-page-first').on('click', function() {
                if ($('.metric').length) {
                    controlUrl = controlUrl + '&page=1';
                    currentPage = 1;
                } else {
                    controlUrl = defaultControlUrl + searchParam + '&page=1';
                    currentPage = 1;
                }
            });

            //The final page link
            $('.degree-page-last').on('click', function() {
                currentPage = totalPages;
                if ($('.metric').length) {
                    controlUrl = controlUrl + '&page=' + currentPage;
                } else {
                    controlUrl = defaultControlUrl + searchParam + '&page=' + currentPage;
                }
            });

            //The "Next" page button
            $('.degree-mixitup-control.mixitup-control-next').on('click', function() {
                if (currentPage != totalPages) {
                    currentPage = currentPage + 1;
                }
                if ($('.metric').length) {
                    controlUrl = controlUrl + '&page=' + currentPage;
                } else {
                    controlUrl = defaultControlUrl + searchParam + '&page=' + currentPage;
                }
            });

            //The the "Previous" page button
            $('.degree-mixitup-control.mixitup-control-prev').on('click', function() {
                if (currentPage != 1) {
                    currentPage = currentPage - 1;
                }
                if ($('.metric').length) {
                    controlUrl = controlUrl + '&page=' + currentPage;
                } else {
                    controlUrl = defaultControlUrl + searchParam + '&page=' + currentPage;
                }
            });

            //Any other page number in the pagination list
            $('.degree-page-number').on('click', function() {
                currentPage = $(this).data('page');
                if ($('.metric').length) {
                    controlUrl = controlUrl + '&page=' + currentPage;
                } else {
                    controlUrl = defaultControlUrl + searchParam + '&page=' + currentPage;
                }
            });

            //Take the new control url that was edited above and set it
            $('.degree-page-item').on('click', function() {
                degrees_mixer_data(controlUrl);
            });

        }

        //Search Filter
        $(inputSearch).on('keyup', function() {
            //Clear the filters
            reset_all();
            //Proceed to update the query
            clearTimeout(degreeSearchTimeout);
            degreeSearchTimeout = setTimeout(function() {
                searchParam = '&search=' + encodeURIComponent(inputSearch.value);
                controlUrl = defaultControlUrl + searchParam + '&page=1';
                degrees_mixer_data(controlUrl);
            }, 500); //delayed for this amount of time
        });


        //Reset everything to the main state
        //Note: This will not call the mixer data function be/c in some
        //circumstances you may want to call it after this reset and some-
        //times no.
        function reset_all()
        {
            //Reset the dropdowns
            $("#degree-type-dropdown").prop("selectedIndex", 0);
            $("#college-school-dropdown").prop("selectedIndex", 0);
            $("#program-type-dropdown").prop("selectedIndex", 0);
            $("#professional-programs-dropdown").prop("selectedIndex", 0);
            //Clear other filter items
            $('.filter-metrics .metric').remove();
        }

        //Delete a Metric Value when Clicked on
        function deleteMetric()
        {
            $('.metric').on('click', function() {
                //Resets the dropdown to 0
                let curMetricDropId = $(this).data('dropid');
                $("#" + curMetricDropId).prop("selectedIndex", 0);
                $(this).remove();
                degreeFilterAction();
                currentPage = 1;
            });
        }

        $(".drop-sel-filter").change(function() {
            let curMetricDropId = $(this).attr("id");
            if ($(this).find('option:selected').val() != '') {
                //Get rid of any mixers that have the same metric group
                $('.metric').each(function() {
                    if ($(this).data('dropid') == curMetricDropId) {
                        $(this).remove();
                    }
                });
                let key = '';
                if (curMetricDropId == 'degree-type-dropdown') {
                    key = 'degree_type';
                } else if (curMetricDropId == 'college-school-dropdown') {
                    key = 'college_school';
                } else if (curMetricDropId == 'program-type-dropdown') {
                    key = 'program_type';
                } else if (curMetricDropId == 'professional-programs-dropdown') {
                    key = 'pre_professional_program';
                }
                //Add metric button to filter list
                $('.filter-metrics').append('<div class="metric" data-key="' + key + '" data-dropid="' + $(this).attr("id") + '" data-val="' + $(this).find('option:selected').val() + '">' + $(this).find('option:selected').text() + '</div>');
                degreeFilterAction();
                inputSearch.value = '';
                deleteMetric();
            }
        });

        function degreeFilterAction()
        {
            categories = '';
            let index = 0;
            $('.metric').each(function() {
                categories += '&meta_query[' + index + '][key]=' + encodeURIComponent($(this).data('key'));
                categories += '&meta_query[' + index + '][value]=' + encodeURIComponent($(this).data('val'));
                index++;
            });
            controlUrl = defaultCategoryUrl + categories;
            degrees_mixer_data(controlUrl + '&page=1');
        }

        //Start the Mixer and pagination off with the first page
        degrees_mixer_data(controlUrl);

        //Load category dropdowns
        loadCategoryDropdowns();

    }
});
